import { render, staticRenderFns } from "./addEdit.vue?vue&type=template&id=8cdb2488&scoped=true&"
import script from "./addEdit.vue?vue&type=script&lang=js&"
export * from "./addEdit.vue?vue&type=script&lang=js&"
import style0 from "./addEdit.vue?vue&type=style&index=0&id=8cdb2488&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cdb2488",
  null
  
)

export default component.exports