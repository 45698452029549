<template>
   <div class="cont">
         <!-- 面包屑 -->
         <el-row class="crumbs-box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
               <el-breadcrumb-item>业务中台 -- {{action}}</el-breadcrumb-item>
               <el-breadcrumb-item>商城管理</el-breadcrumb-item>
               <el-breadcrumb-item to="/goods_list">商品列表</el-breadcrumb-item>
               <el-breadcrumb-item to="/goods_list">商品列表{{action === "edit" ? "编辑" : ""}}</el-breadcrumb-item>
            </el-breadcrumb>
         </el-row>
              <el-row>
                 <el-link class="l-height-50 back_iconfont" type="primary" icon="iconfont iconfanhui1" @click="goBack()">
                    <span v-text="this.action === 'add' ? '添加商品' : '编辑商品'"></span>
                 </el-link>
              </el-row>
          <div class="det-box">
             <div class="box-next" style="width: 60%">
                <el-row class="m-bottom-15">
                   <el-col :span="8" class="t-right l-height-40">
                      <b class="needful">*</b>
                      <span>商品分类：</span>
                   </el-col>
                   <el-col :span="16">
                      <el-select class="m-right-30" v-model="categoryId" placeholder="请选择">
                         <el-option
                             v-for="item in categoryData"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                         </el-option>
                      </el-select>
                      <el-button class="bg-gradient" type="primary" @click="handleAddCategory('/goods_category','0-0')">新增分类</el-button>
                   </el-col>
                </el-row>
                <el-row class="m-bottom-15">
                   <el-col :span="8" class="t-right l-height-40">
                      <span>商品卖点：</span>
                   </el-col>
                   <el-col :span="16">
                      <el-input style="width: 80%;" v-model="usp" placeholder="商品卖点"></el-input>
                   </el-col>
                </el-row>
                <el-row class="m-bottom-15">
                   <el-col :span="8" class="t-right l-height-40">
                      <b class="needful">*</b>
                      <span>商品库存：</span>
                   </el-col>
                   <el-col :span="16">
                      <el-input-number  v-model="quantity" :min="1" :max="99999"></el-input-number>
                   </el-col>
                </el-row>
                <el-row class="m-bottom-15">
                   <el-col :span="8" class="t-right l-height-40">
                      <b class="needful">*</b>
                      <span>商品单价：</span>
                   </el-col>
                   <el-col :span="16">
                      <el-input-number  v-model="price" :min="1" :max="99999"></el-input-number>
                   </el-col>
                </el-row>
                <el-row class="m-bottom-15">
                   <el-col :span="8" class="t-right l-height-40">
                      <b class="needful">*</b>
                      <span>商品标题：</span>
                   </el-col>
                   <el-col :span="16">
                      <el-input style="width: 80%;" v-model="title" placeholder="商品标题"  clearable></el-input>
                   </el-col>
                </el-row>
                <el-row class="m-bottom-15">
                   <el-col :span="8" class="t-right l-height-40">
                      <span>商品封面图：</span>
                   </el-col>
                   <el-col :span="16" style="display: flex" v-if="coverImg.refresh">
                      <img width="148" height="148" style="margin-right: 10px" class="old-pic" v-if="coverImg.isEcho" :src="coverImg.echoUrl" alt="">
                      <el-upload
                          ref="upload"
                          :limit="1"
                          :show-file-list="true"
                          action="#"
                          :auto-upload="false"
                          list-type="picture-card"
                          :on-change="changeCover"
                          :on-preview="handlePictureCardPreview">
                         <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-dialog :visible.sync="coverImg.dialogVisible">
                         <img width="100%" :src="coverImg.dialogImageUrl" alt="">
                      </el-dialog>
                   </el-col>
                </el-row>
                <!--     <el-row class="m-bottom-15">
                   <el-col :span="8" class="t-right l-height-40">
                      <span>商品详情图：</span>
                   </el-col>
                   <el-col :span="16" style="display: flex">
                      <img width="148" height="148" style="margin-right: 10px" class="old-pic" v-if="detailImg.isEcho" :src="detailImg.echoUrl" alt="">
                      <el-upload
                          ref="upload"
                          :limit="3"
                          :show-file-list="true"
                          action="#"
                          :auto-upload="false"
                          list-type="picture-card"
                          :on-change="changeDetail"
                          :on-preview="handlePictureCardPreview">
                         <i class="el-icon-plus"></i>
                      </el-upload>
                      <el-dialog :visible.sync="detailImg.dialogVisible">
                         <img width="100%" :src="detailImg.dialogImageUrl" alt="">
                      </el-dialog>
                   </el-col>
                </el-row>-->
                <el-row class="m-bottom-15">
                   <el-col :span="8" class="t-right l-height-26">
                      <span v-text="$t('msg.remarks_a')">商品描述：</span>
                   </el-col>
                   <el-col :span="16">
                      <el-input class="width-260 type-textarea" type="textarea" v-model="goodsDetail.pcDescribe" placeholder="商品描述"></el-input>
                   </el-col>
                </el-row>
                <el-row class="m-top-15 next-button">
                   <el-button class="m-right-30" type="primary" plain @click="handleReset()" v-text="$t('msg.reset')">重置</el-button>
                   <el-button class="bg-gradient"  type="primary" @click="handleSave" v-text="$t('msg.save')">保存</el-button>
                </el-row>
             </div>
          </div>
   </div>
</template>
<script>
import { mapState } from 'vuex'
import { urlObj } from '@/api/interface'
export default {
   data(){
      return{
         categoryData: [],        // 商品分类目录
         action: '',              // 当前操作行为
         /* 图片 */
         coverImg: {
            isEcho: false,        // 是否回显图片
            echoUrl: '',          // 回显图片路径
            dialogVisible: false, // 是否显示放大图
            dialogImageUrl: '',   // 放大图地址
            refresh: true,        // 刷新组件
         },
         detailImg: {
            isEcho: '',           // 是否回显图片
            echoUrl: '',          // 回显图片路径
            dialogVisible: false, // 是否显示放大图
            dialogImageUrl: '',   // 放大图地址
            refresh: true,        // 刷新组件
         },
         /* 添加表单字段 */
         id: '',               // 商品ID
         hotelId: '',
         categoryId: '',       // 商品分类ID
         title: '',            // 商品标题
         price:'',             // 商品单价
         usp: '',              // 商品卖点
         quantity: '',         // 商品库存
         goodsDetail: {        // 商品描述
            pcDescribe: '',
         },
         tenantId:'',          // 租户id
         storeId: '',          // 商户id
         file: {
            cover: null,
            detail: []
         },
         /* 提示文本 */
         add_success: '添加成功！',
         edit_success: '编辑成功！',
         detail_add_success: '详情图添加成功！',
         detail_edit_success: '详情图添加成功！',
         category_no_null: '分类不能为空！',
         price_no_null: '价格不能为空！',
         title_no_null: '标题不能为空！',
         quantity_no_null: '库存不能为空！',
         confirm: '确定',
         cancel: '取消',
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   mounted() {
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      if (this.companyId === 'PLATFORM'){
         this.companyId = ''
      }
      // 当账号为租户
      if (userInfo.accountType === 'TENANT'){
         this.tenantId = userInfo.tenantId
         this.companyId = userInfo.tenantId
      }
      // 当账号为商户/商家
      if (userInfo.accountType === 'BUSINESS_SHOPPING' || userInfo.accountType === 'STORE') {
         this.tenantId = userInfo.tenantId
         this.storeId = userInfo.storeId
         this.companyId = userInfo.storeId
      }
      this.action = this.$route.query.act
      this.getCategory()
      this.echoGoods()
   },
   beforeDestroy() {
      sessionStorage.removeItem('goodsInfo')
   },
   inject: ['reload'],
   methods: {
      // 编辑时回显数据
      echoGoods(){
         if (!sessionStorage.hasOwnProperty('goodsInfo')) return
         let goodsInfo = JSON.parse(sessionStorage.getItem('goodsInfo'))
         this.hotelId = goodsInfo.hotelId
         this.id = goodsInfo.id
         this.categoryId = goodsInfo.categoryId
         this.usp = goodsInfo.usp
         this.quantity = goodsInfo.quantity
         this.price = goodsInfo.price
         this.title = goodsInfo.title
         if (goodsInfo.coverPath){
            this.coverImg.isEcho = true
            this.coverImg.echoUrl = goodsInfo.coverPath
         }
      },
      // 获取商品二级分类列表
      getCategory(){
         const url = urlObj.getSecondCategory + `/${this.hotelInfo.storeId}`
         this.$axios.get(url, {}).then(res => {
            if (res.success){
               let categoryData = res.records
               this.categoryData = categoryData.map(item => {
                  return {value: item.categoryId, label: item.categoryName}
               })
            }
         }).catch(error => {
            console.log(error)
         })
      },
      // 获取封面图资源
      changeCover(file){
         // 验证文件格式、大小
         const imgFormat = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png' || file.raw.type === 'image/gif';
         const isLt2M = file.size / 1024 / 1024 < 2;
         if (!imgFormat) {
            this.$message.error('上传商品图片只能是JPG/PNG/GIF格式!');
            // 强制刷新局部DOM节点
            this.coverImg.refresh = false
            this.$nextTick(() => {
               this.coverImg.refresh = true
            })
            return
         }
         /*if (!isLt2M) {
            this.$message.error('上传商品图片大小不能超过 2MB!');
            this.coverImg.refresh = false
            this.$nextTick(() => {
               this.coverImg.refresh = true
            })
            return
         }*/
         this.coverImg.isEcho = false
         this.file.cover = file.raw
      },
      // 点击图片查看大图
      handlePictureCardPreview(file){
         this.coverImg.dialogImageUrl = file.url;
         this.coverImg.dialogVisible = true;
      },
      // 获取详情图资源
      changeDetail(file){
         this.file.detail.push(file.raw)
      },
      // 重置商品
      handleReset(){
         this.categoryId = ''
         this.title = ''
         this.price = ''
         this.usp = ''
         this.quantity = ''
         this.goodsDetail.pcDescribe = ''
         this.file.cover = null
         this.coverImg.isEcho = false
         // 重置图片
         this.coverImg.refresh = false
         this.$nextTick(() => {
            this.coverImg.refresh = true
         })
      },
      // 保存商品
      handleSave(){
         // 表单验证
         // 分类不能为空
         if (!this.categoryId) {
            this.$alert(this.category_no_null, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         // 商品标题不能为空
         if (!this.title) {
            this.$alert(this.title_no_null, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         // 商品价格不能为空
         if (!this.price) {
            this.$alert(this.price_no_null, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         // 商品库存不能为空
         if (!this.quantity) {
            this.$alert(this.quantity_no_null, '', {
               confirmButtonText: this.confirm,
               type: 'error'
            })
            return
         }
         let url = urlObj.addGoods
         let param = new FormData()
         if (this.file.cover){
            param.append('file', this.file.cover)
            param.append('module', 'goods')
            param.append('menu', 'list')
            param.append('func', 'image')
            param.append('category', 'cover')
            param.append('isThumb', true)
         }
         param.append('hotelId', this.hotelInfo.id || this.hotelId)
         param.append('categoryId', this.categoryId)
         param.append('title', this.title)
         param.append('price', this.price)
         param.append('usp', this.usp)
         param.append('quantity', this.quantity)
         param.append('goodsDetail.pcDescribe', this.goodsDetail.pcDescribe)
         param.append('status', 'DRAFT')
         param.append('tenantId', this.tenantId)
         param.append('storeId', this.storeId)
         if (this.action === 'edit'){
            url = urlObj.editGoods
            param.append('id', this.id)
         }
         this.$axios.post(url, param, 'file').then(res => {
            if (res.success){
               this.$message({
                  showClose: true,
                  message: this.action === 'add' ? this.add_success : this.edit_success,
                  type: 'success'
               })
               this.goBack()
               // this.bizId = res.records
               // this.file.detail.length > 0 && this.handleSaveDetail()
            }
         })
      },
      // 循环发起保存详情图请求
      async handleSaveDetail(){
         const url = urlObj.uploadDetailFile
         let param = new FormData()
         param.append('module', 'goods')
         param.append('menu', 'list')
         param.append('func', 'image')
         param.append('category', 'image')
         param.append('isThumb', true)
         param.append('bizId', this.bizId)
         for (let i = 0;i <= this.file.detail.length - 1;i ++){
            param.set('file', this.file.detail[i])
            await this.uploadDetail(url, param)
         }
      },
      // 上传商品详情图
      uploadDetail(url, param){
         return new Promise((resolve, reject) => {
            this.$axios.post(url, param, 'file').then(res => {
               if (res.success){
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.detail_add_success : this.detail_edit_success,
                     type: 'success'
                  })
               }
               resolve('success')
            }).catch(error => {
               resolve('success')
            })
         })
      },
      // 跳转分类页
      handleAddCategory(path, sideIndex){
         sessionStorage.setItem('sideIndex', sideIndex)
         this.$router.replace(path)
         this.reload()
      },
      // 返回上页
      goBack(){
         this.$router.go(-1)
      }
   }
}
</script>
<style scoped lang="scss">
.cont{
   min-width: 1120px;
}
.el-row{
   line-height: 30px;
   white-space: nowrap;
}
.det-box{
   /* border: 1px solid red; */
   background: white;
   padding: 10px 20px;
   .box-next{
      width: 60%;
      margin: 0 auto 20px;
      /deep/ .el-input__inner{
         height: 40px;
         line-height: 40px;
      }
   }
   .next-button{
      text-align: center;
      .el-button{
         width: 120px;
      }
   }
   .box-info{
      width: 80%;
      margin: 0 auto;
      /deep/ .el-input__inner{
         height: 40px;
         line-height: 40px;
      }
      .rate-number{
         /deep/ .el-rate__icon{
            height: 40px;
            line-height: 40px;
            font-size: 30px;
         }
      }
   }
   .box_list{
      .el-col-4{
         height: 50px;
         line-height: 50px;
         padding-left: 15px;
         border: 1px solid rgba(204, 204, 204, 1);
         border-width: 0 1px 1px 0;
         &:nth-child(6n){
            border-right: 0;
         }
         &:nth-last-child(1),&:nth-last-child(2),&:nth-last-child(3),&:nth-last-child(4),&:nth-last-child(5),&:nth-last-child(6){
            border-bottom: 0;
         }
      }
      .box-inline{
         display: inline-block;
         cursor: pointer;
      }
   }
   .box-bg{
      background: rgba(204, 204, 204, 0.3);
      border-bottom: 1px solid rgba(204, 204, 204, 1);
   }
   .box-border{
      border: 1px solid rgba(204, 204, 204, 1);
   }
}
.det-box::after{
   content: "";
   display: block;
   clear: both;
}
</style>
